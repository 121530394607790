// $('.fc-carousel').owlCarousel({
//     margin:0,
//     nav: false,
//     navigation: false,
//     dots: false,
//     responsive:{
//         0:{
//             items:1
//         },
//         600:{
//             items:1
//         },
//         1000:{
//             items:1
//         }
//     }
// });

    function checkWidth() {
        var windowSize = $(window).width();

        if (windowSize <= 768) {
            $('.nav-slide').hide().removeClass('container');
            $('.fc-navtab-collapse').slideUp().addClass('fc-slideUp');
            $('.fc-navtab').addClass('nav-justified');
            $('.pbodyRes').hide(); 
        }
        else if (windowSize >= 768) {
            $('.nav-slide').slideDown().addClass('container');
            $('.fc-navtab-collapse').slideDown().removeClass('fc-slideUp');
            $('.fc-navtab').removeClass('nav-justified');
            $('.pbodyRes').show();
        }
    }

    // Execute on load
    checkWidth();
    // Bind event listener
    $(window).resize(checkWidth);


	$('.fc-dropdown').on('click', function(){
		var droparrow = $(this).find('i');

		if ($(droparrow).hasClass('fa-chevron-down')){
			$(droparrow).removeClass('fa-chevron-down').addClass('fa-chevron-up');
		} else 
			$(droparrow).removeClass('fa-chevron-up').addClass('fa-chevron-down');

	});

    $(".fc-subnav-collapse").on('click', function(){
    	$('.nav-slide').slideToggle();
    });

    $(".fc-navtab-dropdown").on('click', function(){
    	$('.fc-slideUp').slideToggle();
    });

    $(".fc-navtab-collapse").on('click', function(){
    	$('.fc-slideUp').slideToggle();
    	$('.fc-dropdown span i.fa').removeClass('fa-chevron-up').addClass('fa-chevron-down');
    });

// Notifications

function fcNotif() {

	$('div.fc-notif.notice .n-icon i.fa').addClass('fa-info-circle');
	//$('div.fc-notif.notice .n-con span').html('NOTICE');

	$('div.fc-notif.success .n-icon i.fa').addClass('fa-check-circle');
	//$('div.fc-notif.success .n-con span').html('SUCCESS');

	$('div.fc-notif.warning .n-icon i.fa').addClass('fa-exclamation-circle');
	//$('div.fc-notif.warning .n-con span').html('WARNING');

	$('div.fc-notif.error .n-icon i.fa').addClass('fa-times-circle');
	//$('div.fc-notif.error .n-con span').html('ERROR');

      $('.tn-msg span').on('click', function(){
        $('.fc-topnotif').slideUp();
    });
}

fcNotif();



$('.n-close').on('click', function (){
	$(this).parent('div').fadeOut();
});

function scaleReset() {

	$('.hovercon div.hov').css('transform', 'scale(0)').removeClass('dmain');

}

$('.hovercon div').hover(

  function() {

    if( $(this).hasClass('hmanila') ){
    	scaleReset();
		$('.dmanila').css('transform', 'scale(1)');
    }
    else if( $(this).hasClass('hluzon') ){
    	scaleReset();
    	$('.dluzon').css('transform', 'scale(1)');
    }
    else if( $(this).hasClass('hvisayas') ){
    	scaleReset()
    	$('.dvisayas').css('transform', 'scale(1)');
    }
    else if( $(this).hasClass('hmindanao') ){
    	scaleReset()
    	$('.dmindanao').css('transform', 'scale(1)');
    }
    else return;
  
  });

$('.fc-panelprice').on("click", function (e) {

	var windowSize = $(window).width();

        if (windowSize <= 768) { 

        	if ($(this).hasClass('panel-collapsed')) {
		        // expand the panel
		        $(this).parents('.panel').find('.pbodyRes').slideUp();
		        $(this).removeClass('panel-collapsed');
		        $(this).find('i').removeClass('fa fa-chevron-up').addClass('fa fa-chevron-down');
		    }
		    else {
		        // collapse the panel
		        $(this).parents('.panel').find('.pbodyRes').slideDown();
		        $(this).addClass('panel-collapsed');
		        $(this).find('i').removeClass('fa fa-chevron-down').addClass('fa fa-chevron-up');
		    }

        }

        else if (windowSize >= 768) { 

        	if ($(this).hasClass('panel-collapsed')) {
		        // expand the panel
		        $(this).parents('.panel').find('.pbodyRes').slideDown();
		        $(this).removeClass('panel-collapsed');
		        $(this).find('i').removeClass('fa fa-chevron-down').addClass('fa fa-chevron-up');
		    }
		    else {
		        // collapse the panel
		        $(this).parents('.panel').find('.pbodyRes').slideUp();
		        $(this).addClass('panel-collapsed');
		        $(this).find('i').removeClass('fa fa-chevron-up').addClass('fa fa-chevron-down');
		    }

        }

        else return;

    
});

$(function(){
    var navIsBig = true;
    var $nav = $('.fc-navbar');
    var $navsep = $('.navbar-separator');

$(document).scroll( function() {
   var value = $(this).scrollTop();

  if ( value > 100 && navIsBig ){
      $nav.addClass('shrink');
      navIsBig = false;
  } else if (value <= 100 && !navIsBig ) {
      $nav.removeClass('shrink');
      navIsBig = true;
  }
 });
 });


// //Sub menu
$( ".dropdown-submenu" ).click(function(event) {
    // stop bootstrap.js to hide the parents
    event.stopPropagation();

    $(this).closest('ul').find('li.open').removeClass('open');
    $(this).addClass('open');
    return;
});


/*announcement*/
$('#announcementModal').modal('show');
