jQuery(function() {
    $(document).ready(function() {
        $('.pbody').hide();  
        $('.fc-panelheading').on("click", function (e) {
            if ($(this).hasClass('panel-collapsed')) {
                // expand the panel
                $(this).parents('.panel').find('.pbody').slideUp();
                $(this).removeClass('panel-collapsed');
                $(this).find('i').removeClass('fa fa-chevron-up').addClass('fa fa-chevron-down');
            }
            else {
                // collapse the panel
                $(this).parents('.panel').find('.pbody').slideDown();
                $(this).addClass('panel-collapsed');
                $(this).find('i').removeClass('fa fa-chevron-down').addClass('fa fa-chevron-up');
            }
        });
    });
});
