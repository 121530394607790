$(document).ready(function() {
	$('#trans-date').datepicker( { format: 'dd-mm-yyyy', todayHighlight: true, } );
	$('#sys-birth').datepicker( { format: 'yyyy-mm-dd' } );
	
	var incrementQuantity = function() {
		var inputValue = parseInt($(this).closest('div').find('input').val());

		if (!inputValue) {
			var inputValue = 0;
		}

		if (inputValue >= 99) {
			$(this).closest('div').find('input').val(99);
			return;
		}

		$(this).closest('div').find('input').val(inputValue + 1);
		return;
	}

	var decrementQuantity = function() {
		var inputValue = parseInt($(this).closest('div').find('input').val());

		if (!inputValue) {
			var inputValue = 0;
		}

		if (inputValue <= 0) {
			$(this).closest('div').find('input').val(0);
			return;
		}

		$(this).closest('div').find('input').val(inputValue - 1);
		return;
	}

	var numericOnly = function(event) {
		if ((event.which < 48 || event.which > 57)) {
		  event.preventDefault();
		}
	};

	var numericWithDecimalOnly = function(event) {
		var charCode = (event.which) ? event.which : event.keyCode;
        	if (charCode != 46 && charCode > 31 
            && (charCode < 48 || charCode > 57))
            	return false;
          	return true;
	};

	var remove_custom_box = function() {
		$(this).closest('div.custom-box').remove();

		if($('#custom-box-container').find('.panel-body .custom-box').length <= 0) {
			$('#custom-box-container').addClass('hidden');
		}
	};

	$('.plusSign').on('click', incrementQuantity);
	$('.minusSign').on('click', decrementQuantity);
	$('.integer').keypress(numericOnly);
	$('.double').keypress(numericWithDecimalOnly);
	$('.btn-remove-pickup').on('click', remove_custom_box);
	
	//pickup box cloning
	var add_pickup_box = function() {
		var container = $('#custom-box-container');
		var blueprint = $('#custom-box-blueprint');

		var copy = blueprint.clone();

		copy.removeAttr('id');

		container.removeClass('hidden');
		container.find('.panel-body').append(copy);

		copy.find('button.add-pickup-box').on('click', add_pickup_box);
		copy.find('button.btn-remove-pickup').on('click', function() {
			$(this).closest('div.custom-box').remove();

			if(container.find('.panel-body .custom-box').length <= 0) {
				container.addClass('hidden');
			}
		});
		copy.find('button.plusSign').on('click', incrementQuantity);
		copy.find('button.minusSign').on('click', decrementQuantity);
		copy.find('.integer').keypress(numericOnly);
		copy.find('.double').keypress(numericWithDecimalOnly);

		copy.find('.pickup_box_type').on('change', function() {
			if($(this).val() === 'custom') {
				copy.find('.custom-pickup-box').removeClass('hidden');
			} else copy.find('.custom-pickup-box').addClass('hidden');
		});
	};

	$('.add-pickup-box').on('click', add_pickup_box);

	//custom box type change
	$('.pickup_box_type').on('change', function() {
		if($(this).val() === 'custom') {
			$(this).closest('div.panel-body').find('.custom-pickup-box').removeClass('hidden');
		} else $(this).closest('div.panel-body').find('.custom-pickup-box').addClass('hidden');
	})
	//endOF:: pickup box cloning

	//guide for delivery/pickup day(s)
	var schedule = $('#pickup_schedule');
	var location = $('#locationList');

	schedule.on('change', function() {

		var value = $(this).val();

		if (location.find('strong[id="' + value + '"]').length) {
			location.find('strong[id="' + value + '"]').removeClass('hidden');
			location.find('strong:not([id="' + value + '"])').addClass('hidden');
			location.removeClass('hidden');
			return;
		}

		location.find('strong').addClass('hidden');
		location.addClass('hidden');

	});
	//endOF:: guide

	
	$('#btnSubmit').on('click', function () {
		$('#btnSubmit').html('<i class="fa fa-spinner fa-spin" style="font-size:14px"></i> Submitting...');
		$('#btnSubmit').attr('disabled', 'disabled');

		$('#frmSubmitForm').submit();
	});

	//SUBMIT FORM FROM PREVIEW
	$("#confirm-box-order").on('click', function() {
		$("#already_reviewed").val(1)
		
		if($('#approved_change_of_address').is(":checked")) {
			$("#update_customer_address").val(1)
		} else {
			$("#update_customer_address").val(0)
		}

		$('#frmSubmitForm').submit();
	});

	//CHECK AVAILABILITY PER AREA
	$("#area").autocomplete({
        source: '/search-areas/' + $(this).val(),
        minLength: 3,
        select: function (event, ui) {
            $(this).val(ui.item.suburb);
            $('#locationList').removeClass('hidden');
            $('#deliveryDay').removeClass('hidden').text(ui.item.days);
            return false;
        }
    }).data("ui-autocomplete")._renderItem = function (ul, item) {
         return $("<li></li>")
             .data("item.autocomplete", item)
             .append("<a>" + item.suburb + ' (' +item.post_code + ')' + "</a>")
             .appendTo(ul);
    };

	// CUSTOMER SUBURB
    $("#customer-suburb").autocomplete({
        source: '/search-areas/' + $(this).val(),
        minLength: 3,
        select: function (event, ui) {
            $(this).val(ui.item.suburb);
            return false;
        }
    }).data("ui-autocomplete")._renderItem = function (ul, item) {
         return $("<li></li>")
             .data("item.autocomplete", item)
             .append("<a>" + item.suburb + ' (' +item.post_code + ')' + "</a>")
             .appendTo(ul);
    };

});