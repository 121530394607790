// Home Javascript

$(document).ready(function() {

    $('.fc-obtn.boxType').on('click', function() {
        window.open(aboutUrl, '_self');
    });

    $('.fc-obtn.testimonials').on('click', function() {
        window.open(testiUrl, '_self');
    });

    $('.fc-obtn.shipping').on('click', function() {
        window.open(shippingUrl, '_self');
    });

    $('.fc-obtn.boxOrder').on('click', function() {
        window.open(boxOrderUrl, '_self');
    });

    $('.airFreight').on('click', function() {
        window.open(airFreightUrl, '_self');
    });

    $('.forexdemayo').on('click', function() {
        window.open(forexdemayoUrl, '_self');
    });

    $('.carousel').carousel({
        interval: 10000 //changes the speed
    })

    $('.btnTrackNow').on('click', function() {
        var reference_number = $(this).closest('div.fc-header-track').find('#reference_number').val();
        var uri = $(this).attr('data-link') + reference_number;

        if (reference_number.length == 0) {
            return false;
        }

        $('#track-result').find('.modal-body').html('<i class="fa fa-spinner fa-spin" style="font-size:14px"></i> retrieving status...');
        $('#track-result').find('.modal-body').load(siteUrl + 'api/track/' + reference_number);
    });

    $('.integer').keypress(function(event) {
        if ((event.which < 48 || event.which > 57)) {
            event.preventDefault();
        }
    });

    $('.integer').bind("cut copy paste", function(e) {
        e.preventDefault();
    });

    $('.slide6').click(function() {
        window.location.href = 'http://bit.ly/fcapppromo-winners';
    });

});