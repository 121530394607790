/* Script */

jQuery(function() {
	$(document).ready(function() {

		var mirrorCurrentIntoPostalAddress = function() {
			var currentAddress = frmPersonal.find('[name*="rapidform[personal][address][current]"]');

			currentAddress.each( function() {
				var name  = $(this).attr('name');
				var value = $(this).val();

				var targetName = 'rapidform[personal][address][postal]' + name.replace( 'rapidform[personal][address][current]', '' ) ;
				frmPersonal.find('[name="' + targetName + '"]').val( value );
			});
		};

		var frmPersonal  = $('form#frmPersonal');
		var frmDocuments = $('form#frmDocuments');

		$('.datepicker').datepicker()

		$('[data-toggle="popover"]').popover();

		//Active the proper tab of ID
		$('.tab-pane .tab-nav-button').on('click', function() {
	        var currentTabTag  = '#' + $(this).closest( '.tab-pane' ).attr('id');
	        var tabButton      = $('.nav-tabs a[href="' + currentTabTag + '"]').closest('li');
	        tabButton.removeClass('active');

	        var tag             = $(this).attr('href');
	        var tabButtonTarget = $('.nav-tabs a[href="' + tag + '"]').closest('li');
	        tabButtonTarget.addClass('active');
        });

		//Clear current ID when skip button was clicked
        $('.tab-pane .tab-nav-skip').on('click', function() {
        	var tabPanel = $(this).closest( '.tab-pane' );
        	tabPanel.find('input, select').val('');
        });

        $('#tab-driver-license .btn-back').on('click', function() {
        	frmDocuments.find('input[name="rapidform[documents][action]"]').val('back-to-step1');
        	frmDocuments.submit();
        });


        //PERSONAL DETAILS
		frmPersonal.find('input').attr('autocomplete', 'off');

		frmPersonal.on('submit', function( event ) {

			frmPersonal.find('.input-error').removeClass('input-error');  

			//PERSONAL DETAILS
			var first = frmPersonal.find('input[name="rapidform[personal][first]"]');
			var last  = frmPersonal.find('input[name="rapidform[personal][last]"]');
			var dob   = frmPersonal.find('input[name="rapidform[personal][dob]"]');

			first.validateInput( checkIfEmpty );
			last.validateInput( checkIfEmpty );
			dob.validateInput( checkIfEmpty );
			dob.validateInput( checkDateFormat, {
				format: 'mm/dd/yyyy'
			});
			dob.validateInput( checkAge, {
				minimum_age:   18
			}, 'Applicant must be over the age of 18 to use our remittance service.');



			//CONTACT DETAILS
			var email  = frmPersonal.find('input[name="rapidform[personal][email]"]');
			var mobile = frmPersonal.find('input[name="rapidform[personal][mobile-no]"]');

			email.validateInput( checkIfEmpty );
			email.validateInput( checkEmailFormat );
			mobile.validateInput( checkIfEmpty );

			//CURRENT RESIDENTIAL ADDRESS
			var currentStreet     = frmPersonal.find('input[name="rapidform[personal][address][current][street]"]');
			var currentCity       = frmPersonal.find('input[name="rapidform[personal][address][current][city]"]');
			var currentState      = frmPersonal.find('select[name="rapidform[personal][address][current][state]"]');
			var currentPostalcode = frmPersonal.find('input[name="rapidform[personal][address][current][postal-code]"]');

			currentStreet.validateInput( checkIfEmpty );
			currentCity.validateInput( checkIfEmpty );
			currentState.validateInput( checkIfEmpty );
			currentPostalcode.validateInput( checkIfEmpty );
			currentPostalcode.validateInput( checkIfNumeric );

			//PREVIOUS RESIDENTIAL ADDRESS
			var previousStreet     = frmPersonal.find('input[name="rapidform[personal][address][previous][street]"]');
			var previousCity       = frmPersonal.find('input[name="rapidform[personal][address][previous][city]"]');
			var previousState      = frmPersonal.find('select[name="rapidform[personal][address][previous][state]"]');
			var previousPostalcode = frmPersonal.find('input[name="rapidform[personal][address][previous][postal-code]"]');

			if ( previousStreet.val() != "" || previousCity.val() != "" || previousState.val() != "" || previousPostalcode.val() != "" ) {
				previousStreet.validateInput( checkIfEmpty );
				previousCity.validateInput( checkIfEmpty );
				previousState.validateInput( checkIfEmpty );
				previousPostalcode.validateInput( checkIfEmpty );
				previousPostalcode.validateInput( checkIfNumeric );	
			}		

			//POSTAL ADDRESS
			var notResidential = frmPersonal.find('input[name="rapidform[personal][not-residential]"]:checked');

			if ( notResidential.val() == 'Yes' ) {
				var postalStreet     = frmPersonal.find('input[name="rapidform[personal][address][postal][street]"]');
				var postalCity       = frmPersonal.find('input[name="rapidform[personal][address][postal][city]"]');
				var postalState      = frmPersonal.find('select[name="rapidform[personal][address][postal][state]"]');
				var postalPostalcode = frmPersonal.find('input[name="rapidform[personal][address][postal][postal-code]"]');

				postalStreet.validateInput( checkIfEmpty );
				postalCity.validateInput( checkIfEmpty );
				postalState.validateInput( checkIfEmpty );
				postalPostalcode.validateInput( checkIfEmpty );
				postalPostalcode.validateInput( checkIfNumeric );
			}

			var peps = frmPersonal.find('input[name="rapidform[personal][pep]"]');

			peps.validateInput( function( value, param ) {
				return frmPersonal.find('input[name="rapidform[personal][pep]"]:checked').length;
			}, null, 'Please answer the question:\n\n"Are you or were you previously a +Politically Exposed Person (PEP) or are you related / associated with someone who is or was?"');

			var agreement = frmPersonal.find('input[name="rapidform[personal][agree]"]');

			agreement.validateInput( function( value, param ) {
				return agreement.is(':checked');
			}, null, 'Please tick the agreement checkbox if you already read the agreement.');

			if ( frmPersonal.find('.input-error').length ) {
				return false;
			}
			
			frmPersonal.find('button:submit').text('Please wait...')
			frmPersonal.find('button.form-button').attr('disabled', 'disabled');
			return true;
		});

		//Mirror value of current residential address into postal address
		var currentAddress = frmPersonal.find('[name*="rapidform[personal][address][current]"]');
		currentAddress.on('change',  mirrorCurrentIntoPostalAddress );

		$('input[name="rapidform[personal][not-residential]"]').on('change', function() {
			var val     = $(this).attr('value');
			// var checked = $(this).is(':checked');

			var postalAddressInputs = frmPersonal.find('input[name*="rapidform[personal][address][postal]"], select[name*="rapidform[personal][address][postal]"]');

			if ( val == 'Yes' ) {
				postalAddressInputs.removeAttr('disabled', 'disabled');
				postalAddressInputs.attr('required', 'required');
				postalAddressInputs.val('');
				return;
			}

			postalAddressInputs.attr('disabled', 'disabled');
			postalAddressInputs.removeAttr('required', 'required');
			mirrorCurrentIntoPostalAddress();
		});

		//DOCUMENTS
		frmDocuments.find('input').attr('autocomplete', 'off');

		frmDocuments.find('input').attr('autocomplete', 'off');

		frmDocuments.on('submit', function( event ) {

			frmDocuments.find('.input-error').removeClass('input-error');  

			//When customer hit the Back from Driver's LIcense tab, redirect back to step 1
			var action = frmDocuments.find('input[name="rapidform[documents][action]"]').val();

			if ( action == "back-to-step1" ) {
				return true;
			}

			var documents = 0;

			//DRIVER'S LICENSE
			var dl_tabPanel    = $('#tab-driver-license');
			var dl_stateIssued = $('select[name="rapidform[documents][driver-license][state-issued]"]');
			var dl_licenseNo   = $('input[name="rapidform[documents][driver-license][license-no]"]');
			var dl_cardNo      = $('input[name="rapidform[documents][driver-license][card-no]"]');
			var dl_expiryDate  = $('input[name="rapidform[documents][driver-license][expiry-date]"]');

			if ( dl_stateIssued.val() || dl_licenseNo.val() || dl_expiryDate.val() ) {

				documents ++;

				dl_stateIssued.validateInput( checkIfEmpty );
				dl_licenseNo.validateInput( checkIfEmpty );
				dl_licenseNo.validateInput( checkIfNumeric, null, 'DRIVER\'S LICENSE: License number must be all numeric.' );

				if ( dl_stateIssued.val() == "New South Wales" ) {
					dl_cardNo.validateInput( checkIfEmpty )
					dl_cardNo.validateInput( checkIfNumeric, null, 'DRIVER\'S LICENSE: Card number must be all numeric.' );
				}
				
				dl_expiryDate.validateInput( checkIfEmpty );
				dl_expiryDate.validateInput( checkDateFormat, {
					format: 'mm/dd/yyyy'
				}, 'DRIVER\'S LICENSE: Invalid date format for "Expiry Date"');

				
				if ( dl_tabPanel.find('.input-error').length ) {

			        //Tab button
			        $('.nav-tabs li').removeClass('active');
			        var tabButton = $('a[href="#' + dl_tabPanel.attr('id')  + '"]').closest('li');
			        tabButton.addClass('active');

			        //Panel
					$('.tab-pane').removeClass('active');
					dl_tabPanel.addClass('active');
				}
			}

			//MEDICARE CARD
			var mc_tabPanel        = $('#tab-medicare-card');
			var mc_cardNo          = $('input[name="rapidform[documents][medicare-card][card-no]"]');
			var mc_refNo           = $('input[name="rapidform[documents][medicare-card][ref-no]"]');
			var mc_expiryDateMonth = $('select[name="rapidform[documents][medicare-card][expiry-date-month]"]');
			var mc_expiryDateYear  = $('select[name="rapidform[documents][medicare-card][expiry-date-year]"]');


			if ( mc_cardNo.val() || mc_refNo.val() || mc_expiryDateMonth.val() || mc_expiryDateYear.val() ) {

				documents ++;

				mc_cardNo.validateInput( checkIfEmpty );
				mc_cardNo.validateInput( function( value, option ) {
					return $.trim( value.length ) == 10;
				}, null, 'MEDICARE CARD: Card number must be 10 digits.' );
				mc_cardNo.validateInput( checkIfNumeric, null, 'MEDICARE CARD: Card number must be all numeric.' );

				mc_refNo.validateInput( checkIfEmpty );
				mc_refNo.validateInput( function( value, option ) {
					return $.trim( value.length ) == 1;
				}, null, 'MEDICARE CARD: Reference number must be a single digit.' );
				mc_refNo.validateInput( checkIfNumeric, null, 'MEDICARE CARD: Reference number must be a single digit.' );

				mc_expiryDateMonth.validateInput( checkIfEmpty );
				mc_expiryDateYear.validateInput( checkIfEmpty );

				if ( mc_tabPanel.find('.input-error').length ) {

			        //Tab button
			        $('.nav-tabs li').removeClass('active');
			        var tabButton = $('a[href="#' + mc_tabPanel.attr('id')  + '"]').closest('li');
			        tabButton.addClass('active');

			        //Panel
					$('.tab-pane').removeClass('active');
					mc_tabPanel.addClass('active');
				}
			}

			//PASSPORT
			var p_tabPanel       = $('#tab-passport');
			var p_passportNo     = $('input[name="rapidform[documents][passport][passport-no]"]');
			var p_expirationDate = $('input[name="rapidform[documents][passport][expiration-date]"]');
			var p_issuingCountry = $('select[name="rapidform[documents][passport][issuing-country]"]');
			var p_birthCountry   = $('select[name="rapidform[documents][passport][birth-country]"]');
			var p_birthPlace     = $('input[name="rapidform[documents][passport][birth-place]"]');
			var p_givenName      = $('input[name="rapidform[documents][passport][given-name]"]');

			if ( p_passportNo.val() || p_expirationDate.val() || p_issuingCountry.val() || p_birthCountry.val() || p_birthPlace.val() ) {

				documents ++;

				p_passportNo.validateInput( checkIfEmpty );
				p_expirationDate.validateInput( checkIfEmpty );
				p_expirationDate.validateInput( checkDateFormat, {
					format: 'mm/dd/yyyy'
				}, 'PASSPORT: Invalid date format for "Expiration Date"');
				p_issuingCountry.validateInput( checkIfEmpty );
				p_birthCountry.validateInput( checkIfEmpty );
				p_birthPlace.validateInput( checkIfEmpty );

				if ( p_tabPanel.find('.input-error').length ) {

			        //Tab button
			        $('.nav-tabs li').removeClass('active');
			        var tabButton = $('a[href="#' + p_tabPanel.attr('id')  + '"]').closest('li');
			        tabButton.addClass('active');

			        //Panel
					$('.tab-pane').removeClass('active');
					p_tabPanel.addClass('active');
				}
			}

			if ( frmDocuments.find('.input-error').length ) {
				return false;
			}

			if ( documents < 2 ) {
				alert( 'You are required to enter details into at least 2 of the 3 DOCUMENTS.' );
				return false;
			}

			var btnSubmit = frmDocuments.find('button:not(.btn-back):submit');
			btnSubmit.text('Please wait...');
			btnSubmit.attr('disabled', 'disabled');
			return true;
		});


		$('select[name="rapidform[documents][driver-license][state-issued]"]').on('change', function() {
			var state = $(this).val();
			var cardNo = $('.tab-pane#tab-driver-license').find('.card-number');

			if ( state == "New South Wales" ) {
				cardNo.removeClass('hide');
				return;
			}

			cardNo.addClass('hide');
		});

		var btnProcess = $('#btn-process');
		btnProcess.on('click', function( event ) {
			var chkAgree = $('#chk-agree');

			if ( ! chkAgree.is(':checked') ) {
				event.preventDefault();
				
				alert('Please tick the agreement checkbox.');

				return false;
			}

			return confirm('Do you want to submit your application?');
		});

	}); //endOf: $(document).ready
	
}); //endOf: jQuery